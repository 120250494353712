import { useAuth } from '../../content/AuthContext';
import { useState, useEffect } from 'react';
import Cards from '../layouts/Cards';
import softys from '../../assets/JSON/softys.json';
import agrosuper from '../../assets/JSON/agrosuper.json';
import { findUser } from '../../db/users';

const tagRegExp = /\b(TOR)\d{1,2}(E|S)((-)\w{1,4}){1,2}\b/g;

export function Home() {
  const [devices, setDevices] = useState([]);
  const [facility, setFacility] = useState();
  const { user, loading } = useAuth();

  const filterDevices = async (email) => {
    const user = await findUser(email);
    let devicesArray;
    if (user.client === 'SOFTYS') {
      devicesArray = softys.deviceList;
    } else if (user.client === 'ALL') {
      devicesArray = agrosuper.deviceList.concat(softys.deviceList);
    } else {
      devicesArray = agrosuper.deviceList;
    }
    setFacility(user.facility);
    const filter = devicesArray.filter(
      (device) =>
        device.tags.includes(user.facility.toUpperCase().trim()) &&
        device.tags.some((tag) => tag.match(tagRegExp))
    );
    return filter;
  };

  const sortData = (data) => {
    return data.sort((a, b) => {
      const tag1 = a.tags.find((tag) => tag.match(tagRegExp));
      const tag2 = b.tags.find((tag) => tag.match(tagRegExp));
      if (tag1 < tag2) return -1;
      else if (tag1 > tag2) return 1;
      else return 0;
    });
  };

  const fetchData = async () => {
    let data = await filterDevices(user.email);
    data = sortData(data);
    setDevices(data);
  };

  useEffect(() => {
    if (!user) return;
    fetchData();
  }, []);

  if (loading) return <h1>Loading</h1>;

  return (
    <section>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <Cards devices={devices} facility={facility} />
        </div>
      </main>
    </section>
  );
}
