import { sendMessage, sendMessageBotRestart } from '../../lib/slack-api';
import { Alert, Card, TextInput } from 'flowbite-react';
import { useState, useEffect } from 'react';
import { useAuth } from '../../content/AuthContext';

const tagRegExp = /\b(TOR)\d{1,2}(E|S)((-)\w{1,4}){1,2}\b/g;
let tagValue = '';

export default function Cards({ devices, facility }) {
  const [response, setResponse] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDevices, setFilteredDevices] = useState(devices);
  const { user } = useAuth();

  useEffect(() => {
    const filteredDevices = devices.filter((item) => {
      const itemTags = item.tags.join(' ');
      return itemTags.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setFilteredDevices(filteredDevices);
  }, [searchTerm, devices]);

  const handleRestart = async ({ target: { value } }) => {
    setResponse('');
    if (value) {
      sendMessage(facility, user.email, value);
      sendMessageBotRestart(value).then((res) => setResponse(res));
    } else {
      setResponse('error');
    }
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          {response === 'success' && (
            <Alert color="success">
              <span>
                <span className="font-medium">
                  El reinicio se ha enviado con éxito!{' '}
                </span>
                <hr />
                Revise el dispositivo para comprobar el reinicio. Es importante
                tener en consideración que el reinicio puede demorar unos
                segundos según la conexión del dispositivo a internet.
              </span>
            </Alert>
          )}
          {response === 'error' && (
            <Alert color="failure">
              <span>
                <span className="font-medium">¡Ups! Algo salió mal </span>
                <hr />
                No se ha podido establecer conexión con el dispositivo. Porfavor
                revise la
                <a href="https://necolas.github.io/react-native-web/docs/browser-compatibility/">
                  {' '}
                  compatabilidad del buscador{' '}
                </a>
                o ponganse en contacto con el proveedor para solucionar el
                problema.
              </span>
            </Alert>
          )}
          <div className="px-10 pt-10">
            <TextInput
              addon="Buscar"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
            {filteredDevices.map((item, index) => {
              return (
                <Card key={index}>
                  <div className="">
                    {item.tags.map((tag, index) => {
                      if (tag.match(tagRegExp)) {
                        return (
                          <h5
                            className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
                            key={index}
                          >
                            {(tagValue = tag)}
                          </h5>
                        );
                      }
                      return false;
                    })}
                  </div>
                  <div className="">
                    <button
                      onClick={handleRestart}
                      className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      value={tagValue}
                      key={index}
                    >
                      Reiniciar
                    </button>
                    <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                    <p className="inline-flex items-center text-xs font-normal text-gray-500 hover:underline dark:text-gray-400">
                      Dispositivo: {item.model}
                    </p>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
