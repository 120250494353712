export async function sendMessage(facility, email, tag) {
  try {
    const response = await fetch(process.env.REACT_APP_SLACK_URL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: `*Solicitud de reinicio:*
• Facility: ${facility} 
• User: ${email}
• Dispositivo: ${tag}`,
      }),
    });
    if (response.ok || response.status === 0) {
      return "success";
    } else {
      return "error";
    }
  } catch (error) {
    console.log(error);
    return "error";
  }
}

export async function sendMessageBotRestart(tag) {
  try {
    const response = await fetch(process.env.REACT_APP_SLACK_URL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ text: "<@U01TNL05BE2> knox restart " + tag }),
    });
    if (response.ok || response.status === 0) {
      return "success";
    } else {
      return "error";
    }
  } catch (error) {
    console.log(error);
    return "error";
  }
}
