import { useState } from 'react';
import { useAuth } from '../../content/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'flowbite-react';

export function Register() {
  const [user, setUser] = useState({
    email: '',
    password: '',
    facility: 'TORNIQUETE',
    client: 'ALL',
  });
  const [error, setError] = useState();
  const { signup } = useAuth();
  const navigate = useNavigate();

  const handleChange = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signup(user);
      navigate('/');
    } catch (error) {
      console.log(error.message);
      if (error.code === 'auth/internal-error') {
        setError('Correo inválido');
      } else {
        setError(error.message);
      }
    }
  };

  return (
    <section className="h-screen">
      <div className="px-12 py-12 h-full">
        {error && (
          <Alert color="failure">
            <span>
              <span className="font-medium">OPS! Ocurrió un problema</span>
              <hr />
              {error}
            </span>
          </Alert>
        )}
        <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
          <form className="w-full max-w-lg" onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="email"
                  name="email"
                  placeholder="youremail@company.ltd"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="********"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="client"
                >
                  Cliente
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="client"
                    name="client"
                    id="client"
                    onChange={handleChange}
                  >
                    <option value={'ALL'}>All</option>
                    <option value={'SOFTYS'}>Softy</option>
                    <option value={'AGROSUPER'}>Agrosuper</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="facility"
                >
                  Sucursal
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="facility"
                    name="facility"
                    id="facility"
                    onChange={handleChange}
                  >
                    <option value={'TORNIQUETE'}>Torniquete</option>
                    <option value={'TALAGANTE'}>Talagante</option>
                    <option value={'MANUEL RODRIGUEZ'}>Manuel Rodriguez</option>
                    <option value={'LO ECHEVERS'}>Lo Echevers</option>
                    <option value={'EYZAGUIRRE'}>Eyzaguirre</option>
                    <option value={'DOMEYKO'}>Domeyko</option>
                    <option value={'ROSARIO'}>Rosario</option>
                    <option value={'LO MIRANDA'}>Lo Miranda</option>
                    <option value={'DOÑIHUE'}>Doñihue</option>
                    <option value={'SAN VICENTE'}>San Vicente</option>
                    <option value={'OFICINA CENTRAL'}>Oficina Central</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <button className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full">
                  Register
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
